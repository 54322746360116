import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, use } from "gatsby"
import Heading from '../../../components/ui/heading'
import Anchor from '../../../components/ui/anchor'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import FeatureBox from '../../../components/box-image/layout-five'
import {SectionWrap, SectionBottom} from './services-area.style'

const ServicesArea = (props) => {
    const {linkStyle, headingStyle, title, subtitle} = props;
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle
                            subtitle={subtitle}
                            title={title}
                        />
                    </Col>
                </Row>
                <Row>
                    {!!props.data && props.data.map(feature => (
                        <Col lg={4} md={6} className="box-item" key={feature.node.id}>
                            <FeatureBox
                                title={feature.node.title}
                                imageSrc={feature.node.image.childImageSharp}
                                desc={feature.node.excerpt}
                                path={`/it-service/${feature.node.fields.slug}`}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </SectionWrap>
    )
}

ServicesArea.propTypes = {
    headingStyle: PropTypes.object,
    linkStyle: PropTypes.object
}

ServicesArea.defaultProps = {
    headingStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333'
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    }
}

export default ServicesArea;